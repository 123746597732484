.table-bordered {
    border: 1px solid black !important;
    border-collapse: collapse !important;
}

.btn-left {
    float: left !important;
    display: flex !important;
    /*justify-content: flex-end !important;*/
    /*position: absolute;*/
    right: 0 !important;
}

.btn-right {
    float: right !important;
    display: flex !important;
    /*justify-content: flex-end !important;*/
    /*position: absolute;*/
    right: 0 !important;
}

.symbol-autoorder-enabled td {
    font-weight: bold !important;
    background-color: #00ff3961 !important;
}

.span-hms {
    width: 15px !important;
    display: inline-block !important;
    margin-right: 20px !important;
}

.long {
    color: green !important;
}

.short {
    color: red !important;
}

.td-auto {
    border-width: 2px!important;
    border-style: dotted!important;
    border-color: blue!important;
}

.span-auto {
    color: blue!important;
    font-weight: bold;
    font-size: 8pt!important;
}

.td-90 {
    width: 90px !important;
}

.td-130 {
    width: 130px !important;
}

.td-160 {
    width: 160px !important;
}

.editsm{
    /*font-size: 10pt!important;*/
    min-width: 105%!important;
}

.font-medium td{
    font-size: 12pt;
}

.auto-button-save {
    float: right;
}

.color-divider {
    color: silver !important;
}

.autoform-label {
    margin-top: 5px !important;
    /*position: relative!important;*/
    vertical-align: middle !important;
}

.autobot-form {
    padding: 20px;
    margin: 15px auto;
    border: 1px solid #000000;
    border-radius: 10px;
    max-width: 800px !important;
}

.funding-rate {
    color: blue !important;
    /*font-weight:bold!important;*/
}

.header-fixed th {
    position: sticky;
    top: 0;
    z-index: 100;
}

.btn-center {
    /*text-align: center;*/
    margin: auto !important;
    position: relative;
    display: block !important;
    right: 0 !important;
    width: 100% !important;
}

.hidden {
    display: none !important;
}

.position-edit-form .delete-checkbox input {
    /*width: 80px !important;*/
}

.position-edit-form td {
    /*margin: 0!important;*/
    /*padding-left: 5px!important;*/
    /*padding-right: 1px!important;*/
}

.position-value-changed td {
    font-weight: bold !important;
    background-color: #d7edff !important;
}

.position-value-changed input {
    font-weight: bold !important;
}

.position-edit-value input {
    width: 100px !important;
}

.span-small {
    font-size: 8pt !important;
}

.position-edit-value-title .col-md-4 {
    /*text-align: center;*/
}

.delete-checkbox .form-check-input:checked {
    /*background-color: unset !important;*/
    background-color: red !important;
}

.enable-checkbox .form-check-input:checked {
    /*background-color: unset !important;*/
    background-color: green !important;
}

.order-deleted td {
    background-color: #ff000033 !important;
    text-decoration: line-through;
    text-decoration-style: dashed;
}

.order-added td {
    background-color: #00ff0033 !important;
    font-weight: bold !important;
}

.td-order-status-unknown {
    background-color: red !important;
    color: white ! !important;
}

.td-order-volume-error {
    background-color: red !important;
    color: white ! !important;
}

.btn-symbol {
    /*padding: 5px !important;*/
    margin-left: 3px !important;
    margin-right: 12px !important;
    font-size: 10pt !important;
    /*font-weight: bold;*/
    scale: 200% !important;
}