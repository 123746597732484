body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none !important;
  color: white !important;
}

hr {
  margin-top: 5px;
}

.input-feedback {
  color: rgb(235, 54, 54);
  font-size: 14px;
}

.input-success {
  color: rgb(37, 212, 89);
  font-size: 14px;
}

.input-muted {
  font-size: 14px;
  color: rgb(73, 73, 73);;
}
