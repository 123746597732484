.bg-silver {
    background-color: red !important;
}

.table-bordered {
    border: 1px solid black !important;
    border-collapse: collapse !important;
}

.hidden {
    display: none !important;
}

.wallet-tbl th {
    font-weight: bold;
    font-size: 16pt!important;
}

.pnl-plus {
    color: green!important;
    font-weight: bold;
}

.pnl-minus {
    color: red!important;
    font-weight: bold;
}

.c-long {
    color: green!important;
}

.c-short {
    color: red!important;
}

.height-100percent {
    min-height:85vh;
    /*position: relative;*/
    /*vertical-align: middle!important;*/
}

.icon-loading {
    animation: rotating 2s infinite;
}

@keyframes rotating {
    50% {transform: rotate(270deg);}
}

.centered-icon-loading {
    margin-top: 15%;
}

.balance-td{
    max-width: 200px!important;
}

.plain-td1{
    width: 170px!important;
}
.plain-td2{
    width: 300px!important;
}

.tousdt input {
    position: absolute;
    float: right;
    right: 0!important;
    width: 150px!important;
}

.spot-main-total{
    border-width: 2px!important;
    border-style: dotted!important;
    /*border-color: #DDD!important;*/
}