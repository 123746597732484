
.hidden {
    display: none !important;
}

.centered {
    text-align: center;
}

.historyStatusesTable {
    width: 600px !important;
    max-width: 600px !important;
}

.nav-black .nav-item a {
    color: black !important;
}

.nav-item {
    margin-left: 10px;
    margin-right: 10px;
}

.width-65 {
    width: 65px!important;
    max-width: 65px!important;
}

.width-100 {
    width: 100px!important;
    max-width: 100px!important;
}

.font-small {
    font-size: 12pt!important;
}

.font-small2 {
    font-size: 10pt!important;
}

.tr-info-hl-long {
    border-color: green!important;
}

.tr-info-hl-short {
    border-color: red!important;
}

.tr-info-bg-long {
    background-color: #00ff0033!important;
}

.tr-info-bg-short {
    background-color: #ff000033!important;
}

.tr-info-hl {
    border-width: 2px !important;
    border-style: outset!important;
}

.span-info-hl{
    font-weight:bold!important;
}

.align-right {
    text-align: right!important;
}

.btn-orders-toggle {
    width: 30px;
    float: right;
}

.td-group-block {
    max-width: 30px;
    background-color: #bbb !important;
    border:0!important;
}

.btn-groups-toggle {
    width: 60px;
    float: right;
    background-color: #bbb !important;
}

.nobr {
    white-space: nowrap;
}

.div-description {
    max-width: 80vw;
    max-height: 100px;
    overflow-x: auto;
    white-space: nowrap;
    text-overflow: unset;
    color: #999;
}

.tbl-plain-text td {
    font-weight: normal!important;
}

.tbl-info-cell {
    max-width: 15vw;
    overflow-y: scroll;
}

.row-order-main {
    border-color: black !important;
    border-collapse: collapse !important;
    border-size: 1px !important;
}

.row-order-main, .row-order-main td {
    background-color: #dddddd !important;
}

.order-td-info {
    font-size: 10pt;
    overflow: auto !important;
}

.terminal-online-alert {
    font-size: 14pt!important;
}