.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-form {
    display: flex;
    flex-direction: column;

    border-radius: 1rem;
}

.login-form .login-btn {
    border-radius: 2rem;
}