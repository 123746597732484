.telegram-settigns,
.binance-settings {
    padding: 20px;
    margin: 15px auto;

    border: 1px solid #000000;
    border-radius: 10px;
}

.hr-double {
    margin: 20px 0;
    padding: 0;
    height: 1px;
    border: none;
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
}

.hint {
    color: gray
}

.hiding {
    display: none !important;
}