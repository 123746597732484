/*.telegram-settigns,*/
/*.binance-settings {*/
/*    padding: 20px;*/
/*    margin: 15px auto;*/

/*    border: 1px solid #000000;*/
/*    border-radius: 10px;*/
/*}*/

/*.hr-double {*/
/*    margin: 20px 0;*/
/*    padding: 0;*/
/*    height: 1px;*/
/*    border: none;*/
/*    border-top: 1px solid #333;*/
/*    border-bottom: 1px solid #333;*/
/*}*/

/*.hint {*/
/*    color: gray*/
/*}*/

/*.hiding {*/
/*    display: none !important;*/
/*}*/

/*.template-item select, .template-item option, .template-item input {*/
/*    font-size: 8pt !important;*/
/*    font-family: 'Courier 10 Pitch' !important;*/
/*    scale: 10%;*/
/*}*/

.LONG .binance-settings {
    border-color: green !important;
    border-collapse: collapse !important;
    border-width: 2px;
}

.SHORT .binance-settings {
    border-color: red !important;
    border-collapse: collapse !important;
    border-width: 2px;
}

.icon-loading {
    animation: rotating 2s infinite;
}

.template-add-update-remove-section button {
    padding: 2px 12px !Important;
    margin: 0px !Important;
}

.template-add-update-remove-section button svg {
    font-size: 14pt ! important;
}

.leverage-hl-ok {
    border: 1px solid green !important;
}

.leverage-hl-fail {
    border: 1px solid red !important;
}

.leverage-error {
    color: red !important;
}