.dashboard_menu {
    /* position: fixed; */
    width: 100vw;
    padding: 0 1rem 0 1rem;
}

.border {
  border-width: 3px!important;
  border-style: dotted !important;
  border-color: red !important;
}

.testmode{
    margin: auto;
    font-size: 20pt!important;
    font-weight: bold!important;
    color: orange!important;
}

.dashboard_menu .brand {
    font-size: 2em !important;
    padding-left: 1rem;
}

.dashboard_menu .logout-btn {
    border-radius: 2rem;
}

.dashboard_menu .navbar-nav .nav-item {
    display: flex;
    align-items: center;
    margin-left: 1rem;
}

.dashboard_menu .navbar-nav .nav-item a {
    margin: 0;
    padding: 0;
}

.menu-info {
    color: white;
}

.menu-info p {
    color: rgb(214, 214, 214);
    font-size: 14px;
    margin: 0;
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    .dashboard_menu .navbar-nav .nav-item {
        margin-left: 0;
        padding-bottom: 1rem;
    }
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
}